import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AdsGETApiAuth, AdsPOSTApi, AdsPUTApi, AdsPUTApiToken } from "../../../../Constant/api";
import { slugs } from "../../../../Constant/slugs";
import { AlertComponent } from "../../../AlertComponent";
import { CustomButton } from "../../../CustomButtom";
import ImageUpload from "../../../ImageUpload";
import { login } from '../../../../redux/action';
const studentRoles = ["subscriber", "student"]
export default function FormComponent(props) {
  const { FetchData } = props;
  const history = useHistory()
  const [ID, setID] = React.useState(FetchData.id)
  const Login = useSelector((state) => state.LoginState);

  const [author, setAuthor] = React.useState(FetchData.author)
  const [Title, setTitle] = React.useState(FetchData.title.rendered)
  const [UserRole, setUserRole] = React.useState(FetchData.meta.user_role)
  const [ProfilePicture, setProfilePicture] = React.useState(FetchData.meta.user_profile_picture)
  const [SubscriptionStart, setSubscriptionStart] = React.useState(FetchData.meta.user_subscription_start)
  const [SubscriptionEnd, setSubscriptionEnd] = React.useState(FetchData.meta.user_subscription_end)
  const [PhoneNumber, setPhoneNumber] = React.useState(FetchData.meta.user_phone_number)
  const [VerificationStatus, setVerificationStatus] = React.useState(FetchData.meta.verification_status)
  const [userID, setUserID] = React.useState(FetchData.meta.user_id)
  const [userName, setUserName] = React.useState(FetchData.meta.user_name)
  const [Password, setPassword] = React.useState("")
  const [userEmail, setUserEmail] = React.useState(FetchData.meta.user_email)
  const [userVerificationHistory, setUserVerificationHistory] = React.useState(FetchData.meta.user_verification_history)

  const [verifiedExamList, setVerifiedExamList] = React.useState(
    FetchData.meta.verified_exam_list == "" ? [] :
      JSON.parse(FetchData.meta.verified_exam_list)
  )
  const [BatchExamDeleteIndex, setBatchExamIndex] = React.useState("")
  const [BatchExamDeleteModal, setBatchExamDeleteModal] = React.useState(false)
  const [BatchExam, setBatchExam] = React.useState(
    FetchData.meta.verified_exam_list == "" ? [] :
      Object.values(JSON.parse(FetchData.meta.verified_exam_list)))
  const [BatchExamCache, setBatchExamCache] = React.useState(
    FetchData.meta.verified_exam_list == "" ? [] :
      Object.values(JSON.parse(FetchData.meta.verified_exam_list)))

  const [batchID, setBatchID] = React.useState(FetchData.meta.batch_id)
  const [batchName, setBatchName] = React.useState(FetchData.meta.batch_name)
  const [batchModal, setBatchModal] = React.useState(false)
  const [batchList, setBatchList] = React.useState([])
  const [VerifiedbatchList, setVerifiedBatchList] = React.useState([])
  const [AddNew, setAddNew] = React.useState(false)
  const [serachName, setSearchName] = React.useState("")
  const [ExamArray, setExamArray] = React.useState([])
  const [ExamArray1, setExamArray1] = React.useState([])

  const [AddNewBook, setAddNewBook] = React.useState(false)
  const [serachBookName, setSearchBookName] = React.useState("")
  const [BookArray, setBookArray] = React.useState([])
  const [BookArray1, setBookArray1] = React.useState([])
  // const [VerifiedBook, setVerifiedBook] = React.useState(
  //   FetchData.meta.verified_book_list == "" ? [] :
  //     JSON.parse(FetchData.meta.verified_book_list))
  // const [VerifiedBookCache, setVerifiedBookCache] = React.useState(
  //   FetchData.meta.verified_book_list == "" ? [] :
  //     JSON.parse(FetchData.meta.verified_book_list))
  const [BookDeleteIndex, setBookIndex] = React.useState("")
  const [BookDeleteModal, setBookDeleteModal] = React.useState(false)

  const [AddNewCourse, setAddNewCourse] = React.useState(false)
  const [serachCourseName, setSearchCourseName] = React.useState("")
  const [CourseArray, setCourseArray] = React.useState([])
  const [CourseArray1, setCourseArray1] = React.useState([])
  const [CategoryArray, setCategoryArray] = React.useState([])
  // const [VerifiedCourse, setVerifiedCourse] = React.useState(
  //   FetchData.meta.verified_course_list == "" ? [] :
  //     JSON.parse(FetchData.meta.verified_course_list))
  // const [VerifiedCourseCache, setVerifiedCourseCache] = React.useState(
  // FetchData.meta.verified_course_list == "" ? [] :
  //   JSON.parse(FetchData.meta.verified_course_list))
  const [CourseDeleteIndex, setCourseIndex] = React.useState("")
  const [CourseDeleteModal, setCourseDeleteModal] = React.useState(false)
  const [IDCardNumber, setIDCardNumber] = React.useState(FetchData.meta.id_card_number)
  const [ClassShift, setClassShift] = React.useState(FetchData.meta.class_shift)
  const [AdmissionDate, setAdmissionDate] = React.useState(FetchData.meta.admission_date)
  const [PayedRs, setPayedRs] = React.useState(FetchData.meta.payed_rs)
  const [DueRs, setDueRs] = React.useState(FetchData.meta.due_rs)
  const [PaymentDueDate, setPaymentDueDate] = React.useState(FetchData.meta.due_payment_date)
  const [toStudentModel, setToStudentModal] = React.useState(false)
  const [batchSelectList, setBatchSelectList] = React.useState([])
  const [batchCacheList, setBatchCacheList] = React.useState([])
  

  const [EditRemarks2, setEditRemarks2] = React.useState("")
  const [subscriptionTime, setSubscriptionTime] = React.useState("")
  const [Datapassword, setDataPassword] = React.useState(FetchData.id == "" ? true : false)
  const [choose, setChoose] = React.useState(0)

  const dispatch = useDispatch()

  const [reload, setReload] = React.useState(false)

  const [AlertShow, setAlertShow] = React.useState(false)
  const [Alertcomp, setAlertcomp] = React.useState("")
  const [UBTC, setUBTC] = React.useState(0)

  const AfterUserCreate = (id) => {
    const data = {
      "title": Title,
      "status": "publish",
      "author": id,
      "meta": {
        "user_role": UserRole,
        "user_profile_picture": ProfilePicture,
        "user_subscription_start": SubscriptionStart,
        "user_subscription_end": SubscriptionEnd,
        "user_subscription_time": subscriptionTime,
        "user_phone_number": PhoneNumber,
        "verification_status": VerificationStatus,
        "verified_exam_list": JSON.stringify(BatchExam),
        // "verified_book_list": JSON.stringify(VerifiedBook),
        // "verified_course_list": JSON.stringify(VerifiedCourse),
        "user_id": `${id}`,
        "user_name": userName,
        "user_email": userEmail,
        "user_verification_history": userVerificationHistory,
        "batch_id": batchID,
        "batch_name": batchName,
        "id_card_number": `${IDCardNumber}`,
        "class_shift": `${ClassShift}`,
        "admission_date": `${AdmissionDate}`,
        "payed_rs": `${PayedRs}`,
        "due_rs": `${DueRs}`,
        "due_payment_date": `${PaymentDueDate}`

      }
    }
    if (ID === "")
      AdsPOSTApi(data, slugs.db_slug_user_data)
        .then((res) => {
          setReload(false)
          setID(res.data.id)
          setAlertShow(true)
          setAlertcomp("Book Added Successfully")
          history.push(`${slugs.student_details}${res.data.slug}`)
        }).catch(err => {
          setReload(false)
          console.log(err)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
    else
      AdsPUTApi(data, slugs.db_slug_user_data, ID)
        .then((res) => {
          setReload(false)
          setAlertShow(true)
          setAlertcomp(`${Title} edited Successfully`)
        }).catch(err => {
          console.log(err)
          console.warn(err.response.data)
          console.log("Response Status: hello11", err.response.status);
          console.log("Response Headers:", err.response.headers);
          setReload(false)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
  }
  const EditInfo = () => {
    setReload(true)

    if (ID == "") {
      const dataWithPassword = {
        "username": userName,
        "email": userEmail,
        "password": Password,
        "roles": UserRole
      }
      AdsPOSTApi(dataWithPassword, "users")
        .then(res => {
          AfterUserCreate(res.data.id)
        })
        .catch(e =>
          console.log(e)
        )
    }
    else {
      if (Datapassword) {
        const dataWithPassword = {
          "password": Password,
          "roles": UserRole
        }
        if (Login.userID !== userID)
          AdsPUTApi(dataWithPassword, `users`, `${userID}`)
            .then(res => {
              AfterUserCreate(userID)
            })
            .catch(e =>
              console.log(e)
            )
        else {
          setAlertShow(true)
          setAlertcomp("You are not allowed to cahnge owns password")
          setReload(false)
        }
      }
      else {
        const data = {
          "roles": UserRole
        }
        AdsPUTApi(data, `users`, `${userID}`)
          .then(res => {
            AfterUserCreate(userID)
          })
          .catch(e =>
            console.log(e)
          )
      }
    }
  }

  useEffect(() => {
    // BatchList()
    FetchExamList()
    BatchList()
    BatchOrders()
  }, [])
  const FetchExamList = () => {
    AdsGETApiAuth(
      { per_page: 300, _fields: "id,slug,title,meta.featured_image,meta.exam_type,meta.category_id,meta.exam_type,meta.exam_sub_type,meta.exam_sub_type2" },
      slugs.db_slug_paid_exam
    )
      .then((res) => {
        setExamArray1(res.data)
        setExamArray(res.data)
      })
      .catch(e => {
        console.log(e)
      })
  }
  const BatchList = () => {
    AdsGETApiAuth(
      { per_page: 100, _fields: "id,title,meta.category_name" },
      slugs.db_slug_exam_packages
    )
      .then((res) => {
        setBatchList(res.data)
      })
      .catch(e => {
        console.log(e)
      })
  }
  const BatchOrders = () => {
    AdsGETApiAuth(
      {
        per_page: 100, _fields: "id,title,meta",
        "filter[meta_query][1][key]": "student_id_order",
        "filter[meta_query][1][value][0]": userID,
        "filter[meta_query][1][compare]": "=",
      },
      slugs.db_slug_batch_orders
    )
      .then((res) => {
        setVerifiedBatchList(res.data)
      })
      .catch(e => {
        console.log(e)
      })
  }
  const MappingExam = ({ item }) => {
    const EnrollmentSearch = Object.values(BatchExamCache).filter(a => a.exam_id == item.id)
    const [CheckON, setCheckON] = React.useState(EnrollmentSearch.length == 1 ? true : false)
    return (
      <>
        <td onClick={() => {
          setCheckON(true)
          if (BatchExamCache.filter(a => a.exam_id == item.id).length == 0) {
            BatchExamCache.push({
              exam_id: item.id,
              featured_image: item.meta.featured_image,
              name: item.title.rendered,
              exam_type: item.meta.exam_type,
              slug: item.slug
            })
            // BatchExamIDCache.push(item.id)
          }
        }}>
          {CheckON ?
            <i className="fa fa-check-square-o" />
            :
            <i className="fa fa-square-o" />
          }
        </td>
        <td>{item.id}</td>
        <td>{item.title.rendered}</td>
        <td> {item.meta.exam_sub_type} {item.meta.exam_sub_type2}</td>
        <td style={{ cursor: "pointer" }} onClick={() => {
          setCheckON(true)
          if (BatchExamCache.filter(a => a.exam_id == item.id).length == 0) {
            BatchExamCache.push({
              exam_id: item.id,
              featured_image: item.meta.featured_image,
              name: item.title.rendered,
              exam_type: item.meta.exam_type,
              slug: item.slug
            })
            // BatchExamIDCache.push(item.id)
          }
        }}
        >Choose</td>
      </>
    )
  }
  
  const MappingBatch = ({ item }) => {
    // var allArray = Object.values(batchCacheList).concat(Object.values(VerifiedbatchList))
    var allArray = []
    const EnrollmentSearch = allArray.filter(a => a.meta.batch_id_order == item.id)
    const [CheckON, setCheckON] = React.useState(EnrollmentSearch.length == 1 ? true : false)
    const clickFunction = () => {
      if (!CheckON)
        setCheckON(true)
      else
        setCheckON(false)
      var allOrders = batchCacheList
      if (batchCacheList.filter(a => a.meta.batch_id_order == item.id).length == 0) {
        var data = {
          "title": `${ID}-${item.id}`,
          "status": "publish",
          "meta": {
            "batch_name_order": `${item.title.rendered}`,
            "batch_id_order": `${item.id}`,
            "student_id_order": `${userID}`,
            "student_email_order": userEmail,
            "student_phone_order": `${PhoneNumber}`,
            "student_username_order": userName,
            "paid_amount": `0`,
            "amount_to_pay": `0`,
            "verification_status": "Verified",
            "verification_method": "Manual",
            "verification_date": ``,
            "verification_id": ``
          }
        }
        batchCacheList.push(data)
        // setVerifiedBook(allOrders)
        // BatchExamIDCache.push(item.id)
      }
    }
    return (
      <>
        <td onClick={() => { clickFunction() }}>
          {CheckON ?
            <i className="fa fa-check-square-o" />
            :
            <i className="fa fa-square-o" />
          }
        </td>
        <td onClick={() => { clickFunction() }}>{item.id}</td>
        <td onClick={() => { clickFunction() }}>{item.title.rendered}</td>
        <td onClick={() => { clickFunction() }}>{item.meta.category_name}</td>
        {/* <td style={{ cursor: "pointer" }} onClick={() => { clickFunction() }}
        >Choose</td> */}
      </>
    )
  }
  return (
    <div>
      <div className="row">
        <div className="col-sm-2 col-1"></div>
        <div className="col-sm-8 col-10 form-view mb-3">
          <h4><center>Student Information </center></h4>
          <table>
            <tbody>
              <tr>
                <td className="col-3">Student Name </td>
                <td className="col-6">
                  <input className="input-common"
                    value={Title} placeholder="Name"
                    onChange={(event) => { setTitle(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Student Role</td>
                <td className="col-6">
                  <CustomButton setData={setUserRole} Data={UserRole} DataValue={"subscriber"} />
                  <CustomButton setData={setUserRole} Data={UserRole} DataValue={"student"} />
                  {/* <CustomButton setData={setUserRole} Data={UserRole} DataValue={"teacher"} /> */}
                  <CustomButton setData={setUserRole} Data={UserRole} DataValue={"author"} />
                </td>
              </tr>
              {/* <tr>
                <td className="col-3">Verification Status</td>
                <td className="col-6">
                  <CustomButton setData={setVerificationStatus} Data={VerificationStatus} DataValue={"verified"} />
                  <CustomButton setData={setVerificationStatus} Data={VerificationStatus} DataValue={"None"} />
                </td>
              </tr> */}
              {/* <tr>
                <td className="col-3">Subscription Start</td>
                <td className="col-6">
                  <input className="input-common" type="date"
                    value={SubscriptionStart} placeholder="Subscription Start"
                    onChange={(event) => { setSubscriptionStart(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Subscription End</td>
                <td className="col-6">
                  <input className="input-common" type="date"
                    value={SubscriptionEnd} placeholder="Subscription End"
                    onChange={(event) => { setSubscriptionEnd(event.target.value) }}
                  />
                </td>
              </tr> */}
              <tr>
                <td className="col-3">Profile Picture</td>
                <td className="col-6">
                  <ImageUpload
                    ImageUrl={ProfilePicture}
                    setImageUrl={setProfilePicture}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Phone Number</td>
                <td className="col-6">
                  <input className="input-common"
                    value={PhoneNumber} placeholder="Phone Number"
                    onChange={(event) => { setPhoneNumber(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">User Name</td>
                <td className="col-6">
                  {ID == "" ?
                    <input className="input-common"
                      value={userName} placeholder="User Name"
                      onChange={(event) => { setUserName(event.target.value) }}
                    />
                    :
                    <div className="input-common" >{userName}</div>
                  }
                </td>
              </tr>
              <tr>
                <td className="col-3">User Email</td>
                <td className="col-6">
                  {ID == "" ?
                    <input className="input-common"
                      value={userEmail} placeholder="User Email"
                      onChange={(event) => { setUserEmail(event.target.value) }}
                    />
                    :
                    <div className="input-common" >{userEmail}</div>
                  }
                </td>
              </tr>
              {/* <tr>
                <td className="col-3">User ID</td>
                <td className="col-6">
                  <input className="input-common"
                    value={userID} placeholder="User ID"
                    onChange={(event) => { setUserID(event.target.value) }}
                  />
                </td>
              </tr> */}
              <tr>
                <td className="col-3">Password</td>
                <td className="col-6">
                  {Datapassword ?
                    <input className="input-common"
                      value={Password} placeholder="Password"
                      onChange={(event) => { setPassword(event.target.value) }}
                    />
                    :
                    <div>
                      <button className="border-0 bg-transparent" style={{ margin: "3px 0" }}
                        onClick={() => {
                          setDataPassword(true)
                          setPassword("")
                        }}
                      >
                        <div className={"passive-option"}>
                          Change Password
                        </div>
                      </button>
                    </div>
                  }
                </td>
              </tr>
            </tbody>
          </table>
          {Login.userRole == "author" &&
            <div className="row center m-2">
              <div className=" col-3" style={{ padding: "0 10px" }}>
                <div className="sign-in-button-4"
                  onClick={() => {
                    EditInfo()
                  }}
                >
                  Update Student
                </div>
              </div>
            </div>
          }
        </div>
      </div>
      {/* {Login.userRole == "author" && */}
      {Login.userRole == "author" &&
        <div className="row">
          <div className="col-sm-2 col-1"></div>
          <div className="col-sm-8 col-10 form-view mb-3">
            <h4><center>Exam List</center></h4>
            <table>
              <tbody>
                <tr>
                  <td>Exam ID</td>
                  <td>Exam Name</td>
                  <td>Exam Type</td>
                </tr>

                {BatchExam.map((item, index) => (
                  <tr key={index}>
                    <td>{item.exam_id}</td>
                    <td>{item.name}</td>
                    <td style={{ cursor: "pointer" }} onClick={() => {
                      setBatchExamIndex(index)
                      setBatchExamDeleteModal(true)
                      // BatchExam.splice(index, 1)
                    }}>Delete</td>
                  </tr>
                ))}
                <tr>
                  <td style={{ cursor: "pointer" }} onClick={() => {
                    setAddNew(true)
                  }}>Add New</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      }
      {BatchExamDeleteModal &&
        <section className="modal" >
          <div className="row">
            <div className="col-md-3 col-1"></div>
            <div className="col-md-6 col-10 shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'hidden', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setBatchExamDeleteModal(false)
                  }}
                >X</div>
                Do you really want to delete Exam?
                <table>
                  <tbody>
                    <tr>
                      <td>Exam Name : </td>
                      <td>{BatchExam[BatchExamDeleteIndex].name}</td>
                    </tr>
                    <tr>
                      <td>Exam ID : </td>
                      <td>{BatchExam[BatchExamDeleteIndex].exam_id}</td>
                    </tr>
                  </tbody>
                </table>
                <div className="row" >
                  <div className="col-1 center " />
                  <div className="col-5 center " >
                    <div className="sign-in-button-4" style={{ width: "80%" }}
                      onClick={() => {
                        setBatchExamDeleteModal(false)
                      }}
                    >
                      Cancel
                    </div>
                  </div>
                  <div className="col-5 center">
                    <div className="sign-in-button-4" style={{ width: "80%" }}
                      onClick={() => {
                        BatchExam.splice(BatchExamDeleteIndex, 1)
                        // BatchExamID.splice(BatchExamDeleteIndex, 1)
                        setBatchExamDeleteModal(false)
                      }}
                    >
                      OK
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      }

      {AddNew &&
        <section className="modal" >
          <div className="row">
            <div className="col-md-3 col-1"></div>
            <div className="col-md-6 col-10 shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'scroll', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setChoose(0)
                    setAddNew(false)
                  }}
                >X</div>
                {/* <div className="col-md-6 row">
                  <div className="col-1" />
                  <input className="col-3" style={{ margin: "1px 2px", padding: "3px 2px" }}
                    value={serachName} placeholder="Name"
                    onChange={(event) => {
                      setSearchName(event.target.value)
                      setExamArray(ExamArray1.filter(a => a.title.rendered.toLowerCase().includes(event.target.value.toLowerCase())))
                    }}
                  />
                </div> */}
                <div className="row" style={{ width: "100%", justifyContent: "space-evenly", cursor: "pointer", border: "1px solid #999" }} >
                  <input className="col-3" style={{ margin: "1px 2px", padding: "3px 2px" }}
                    value={serachName} placeholder="Name"
                    onChange={(event) => {
                      setSearchName(event.target.value)
                      setExamArray(ExamArray1.filter(a => a.title.rendered.toLowerCase().includes(event.target.value.toLowerCase())))
                    }}
                  />
                  <div className={UBTC == 0 ? "examselectStudent" : "examunselectStudent"}
                    onClick={() => {
                      setUBTC(0)
                      setExamArray(ExamArray1.filter(a => a.meta.exam_type == "UBT"))
                    }}
                  >
                    UBT
                  </div>
                  <div className={UBTC == 1 ? "examselectStudent" : "examunselectStudent"} onClick={() => {
                    setUBTC(1)
                    setExamArray(ExamArray1.filter(a => a.meta.exam_type == "Chapter"))
                  }} >
                    ChapterWise
                  </div>
                </div>

                <div style={{ height: "400px", overflowY: "scroll" }}>
                  <table>
                    <tbody>
                      <tr>
                        <td></td>
                        <td>Exam ID</td>
                        <td>Exam Name</td>
                        <td>Exam Type</td>
                        <td>Choose</td>
                      </tr>
                      {ExamArray.map((item, index) => (
                        <tr key={index}>
                          <MappingExam item={item} />
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div style={{ padding: "10px" }}>
                  <div className="row">
                    <div className="col-1" />
                    <div className="col-5 center " >
                      <div className="sign-in-button-4" style={{ width: "80%" }}
                        onClick={() => {
                          setBatchExamCache(BatchExam)
                          // setBatchExamIDCache(BatchExamID)
                          setChoose(0)
                          setAddNew(false)
                        }}
                      >
                        Cancel
                      </div>
                    </div>
                    <div className="col-5 center">
                      <div className="sign-in-button-4" style={{ width: "80%" }}
                        onClick={() => {
                          setBatchExam(BatchExamCache)
                          // setBatchExamID(BatchExamIDCache)
                          setChoose(0)
                          setAddNew(false)
                        }}
                      >
                        Done
                      </div>
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </section>
      }
      {Login.userRole == "author" &&
        <div className="row">
          <div className="col-sm-2 col-1"></div>
          <div className="col-sm-8 col-10 form-view mb-3">
            <h4><center>Batch List</center></h4>
            <table>
              <tbody>
                <tr>
                  <td>ID</td>
                  <td>Batch</td>
                  <td>Student Name</td>
                  <td>Verification</td>
                  <td>Amount</td>
                  {/* <td>Delete</td> */}
                </tr>
                {VerifiedbatchList.map((item, index) => (
                  <tr key={index}>
                    <td>{item.id}</td>
                    <td>{item.meta.batch_name_order}</td>
                    <td>{item.meta.student_username_order}</td>
                    <td>{item.meta.verification_status}</td>
                    <td>{item.meta.paid_amount}</td>
                  </tr>
                ))}

              </tbody>
            </table>
            <div className="row center">
              <div className="col-3 center " >
                <div className="sign-in-button-4" style={{ width: "80%" }}
                  onClick={() => {
                    setToStudentModal(true)
                  }}
                >
                  Add New
                </div>
              </div>
            </div>
          </div>
        </div>
      }


      {toStudentModel &&
        <section className="modal" >
          <div className="row" style={{ overflow: "hidden" }}>
            <div className="col-md-2 col-1"></div>
            <div className="col-md-8 col-10  shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'hidden', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setToStudentModal(false)
                  }}
                >X</div>

                Batches List
                <div style={{ height: "50vh", overflowY: "scroll", }}>
                  <table style={{}}>
                    <thead>
                      <tr>
                        <td>SN</td>
                        <td>Batch</td>
                        <td>Batch ID</td>
                        <td>Category</td>
                      </tr>
                      {batchList.map((item, index) => (
                        <tr key={index}>
                          <MappingBatch item={item} />
                        </tr>
                      ))}
                    </thead>
                  </table>
                </div>
                <div className="row center">
                  <div className="col-3 center " >
                    <div className="sign-in-button-4" style={{ width: "80%" }}
                      onClick={() => {
                        setToStudentModal(false)
                      }}
                    >
                      Cancel
                    </div>
                  </div>
                  <div className="col-3 center">
                    <div className="sign-in-button-4" style={{ width: "80%" }}
                      onClick={() => {
                        for (let i = 0; i < batchCacheList.length; i++) {
                          setReload(true)
                          AdsPOSTApi(batchCacheList[i], slugs.db_slug_batch_orders)
                            .then((res) => {
                              if (i == batchCacheList.length - 1) {
                                setReload(false)
                                BatchOrders()
                                setToStudentModal(false)
                                window.location.reload(false);
                              }
                            }).catch(err => {
                            })
                        }
                      }}
                    >
                      Verify Selected Batch
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      }
      {
        reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
      {
        AlertShow &&
        <AlertComponent Alertcomp={Alertcomp} setAlertShow={setAlertShow} />
      }
    </div >
  )

}