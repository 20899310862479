import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { AdsGETApi, MainUrl, signInImage } from '../../../Constant/api';
import { slugs } from '../../../Constant/slugs';
import ContactUS from '../../ContactUS';
// import ForgetPasswordComponent from './ForgetPassword';
import { Carousel } from 'react-responsive-carousel';

export default function Dashboard() {
	const [userName, setUserName] = React.useState("")
	const [password, setPassword] = React.useState("")
	const [confirmPassword, setConfirmPassword] = React.useState("")
	const [email, setEmail] = React.useState("")
	const [PhoneNumber, setPhoneNumer] = React.useState("")

	const [reload, setReload] = React.useState(false)
	const [passwordShow, setPasswordShow] = React.useState(false)
	const [AlertShow, setAlertShow] = React.useState(false)
	const [Alertcomp, setAlertcomp] = React.useState("")
	const [Register, setRegister] = React.useState(false)
	const [loginSuccess, setLoginSuccess] = React.useState(false)
	const [forgetPasswordPressed, setForgetPasswordPressed] = React.useState(false)
	const [sliderDesktop, setSliderDesktop] = React.useState([])

	useEffect(() => {
		AdsGETApi({ _fields: "meta" }, "image_home")
			.then((res) => {
				if (res.data.length !== 0) {
					if (res.data[0].meta.picture_url_list.length !== 0) {
						setSliderDesktop(JSON.parse(res.data[0].meta.picture_url_list))
						// setSliderDesktop(JSON.parse(res.data[0].meta.web_picture_url_list))
						// setSliderMobile1(JSON.parse(res.data[0].meta.picture_url_list1))
						// setSliderDesktop1(JSON.parse(res.data[0].meta.web_picture_url_list1))
					}
				}
				console.log("done")
			})
			.catch(err => {
				console.log(err)
			}).finally(() => {
			})

	}, [])
	return (
		<div style={{ backgroundColor: "#f2f3f2", minHeight: "100vh", padding: "10px", overflow: 'hidden' }}>
			<div className='row'>
				<div className="row">
					<div style={{ backgroundColor: "#fff", marginTop: "3vh" }}>
						<div>
							<div style={{ display: 'flex', justifyContent: 'center', padding: "0 0 50px" }}>
								<img
									src={signInImage}
									height={"80px"}
								/>
							</div>
							{/* {sliderDesktop.length !== 0 &&
								<CaroselView
									// HeaderDesktopTabimagesPass={sliderDesktop}
									HeaderMobileimagesPass={sliderDesktop}
									transitionTime={500}
									interval={3000}
									centerSlidePercentage={100}
								/>
							} */}
							<div className='row'>
								<div className="col-6">
									<Link className="Link" to={slugs.demo_exam}>
										<div className='shadow center' style={{ cursor: "pointer", padding: "5px", borderRadius: "10px" }}>
											<img src='https://api.diyaeducationcenter.com/wp-content/uploads/2024/12/exam.png'
												style={{ height: "50px", width: "50px" }}
											/>
											<br />
											<div style={{ fontSize: "17px", fontWeight: "bold", margin: "10px" }}>Free Exam</div>
											<div style={{ fontSize: "10px", margin: "10px" }}>भैसेपाटीमा जस्तै UBT परिक्षा freeमा अभ्यास गर्न </div>
										</div>
									</Link>
								</div>
								<div className="col-6">
									<a className="Link" href={MainUrl}>
										<div className='shadow center' style={{ cursor: "pointer", padding: "5px", borderRadius: "10px" }}>
											<img src='https://api.diyaeducationcenter.com/wp-content/uploads/2024/12/house.png'
												style={{ height: "50px", width: "50px" }}
											/>
											<br />
											<div style={{ fontSize: "17px", fontWeight: "bold", margin: "10px" }}>Home</div>
											<div style={{ fontSize: "10px", margin: "10px" }}>गृह पृष्टमा हाम्रो institute को बारेमा जानकारी लिन</div>

										</div>
									</a>
								</div>
							</div>
							<div className='row mt-5'>
								<div className="col-6">
									<Link className="Link" to={`${slugs.exam}/ubt`}>
										<div className='shadow center' style={{ cursor: "pointer", padding: "5px", borderRadius: "10px" }}>
											<img src='https://api.diyaeducationcenter.com/wp-content/uploads/2024/12/exam-time.png'
												style={{ height: "50px", width: "50px" }}
											/>
											<br />
											<div style={{ fontSize: "17px", fontWeight: "bold", margin: "10px" }}>UBT Exam</div>
										</div>
									</Link>
								</div>
								<div className="col-6">
									<Link className="Link" to={`${slugs.exam}/chapter-exam`}>
										<div className='shadow center' style={{ cursor: "pointer", padding: "5px", borderRadius: "10px" }}>
											<img src='https://api.diyaeducationcenter.com/wp-content/uploads/2024/12/exam-time.png'
												style={{ height: "50px", width: "50px" }}
											/>
											<br />
											<div style={{ fontSize: "17px", fontWeight: "bold", margin: "10px" }}>Chapter Exam</div>
										</div>
									</Link>
								</div>
							</div>

							<div className='row mt-5'>
								<div className="col-6">
									<Link className="Link" to={slugs.daily_exam}>
										<div className='shadow center' style={{ cursor: "pointer", padding: "5px", borderRadius: "10px" }}>
											<img src='https://api.diyaeducationcenter.com/wp-content/uploads/2024/12/exam-time.png'
												style={{ height: "50px", width: "50px" }}
											/>
											<br />
											<div style={{ fontSize: "17px", fontWeight: "bold", margin: "10px" }}>UBT Exam Packages</div>
											<div style={{ fontSize: "10px", margin: "10px" }}>घरमै बसेर UBT परिक्षा दिन भैसीपाटीमा जस्तै UBT परिक्षा Format मा</div>
										</div>
									</Link>
								</div>
								<div className="col-6">
									<Link className="Link" to={"/ColorVisionSt"}>
										<div className='shadow center' style={{ cursor: "pointer", padding: "5px", borderRadius: "10px" }}>
											<img src='https://api.diyaeducationcenter.com/wp-content/uploads/2024/12/eye.png'
												style={{ height: "50px", width: "50px" }}
											/>
											<br />
											<div style={{ fontSize: "17px", fontWeight: "bold", margin: "10px" }}>Color Vision</div>
											<div style={{ fontSize: "10px", margin: "10px" }}>Contains 12 color vision test. Should correct at least 7</div>
										</div>
									</Link>
								</div>
							</div>
							<div className='row mt-5'>
								<div className="col-6">
									<Link className="Link" to={slugs.books}>
										<div className='shadow center' style={{ cursor: "pointer", padding: "5px", borderRadius: "10px" }}>
											<img src='https://api.diyaeducationcenter.com/wp-content/uploads/2024/12/book.png'
												style={{ height: "50px", width: "50px" }}
											/>
											<br />
											<div style={{ fontSize: "17px", fontWeight: "bold", margin: "10px" }}>Book</div>
											{/* <div style={{ fontSize: "10px", margin: "10px" }}>HRD Book नेपाली र english ,मा </div> */}
										</div>
									</Link>
								</div>
								<div className="col-6">
									<Link className="Link" to={"/profile"}>
										<div className='shadow center' style={{ cursor: "pointer", padding: "5px", borderRadius: "10px" }}>
											<img src='https://api.diyaeducationcenter.com/wp-content/uploads/2024/12/profile-1.png'
												style={{ height: "50px", width: "50px" }}
											/>
											<br />
											<div style={{ fontSize: "17px", fontWeight: "bold", margin: "10px" }}>Profile</div>
											{/* <div style={{ fontSize: "10px", margin: "10px" }}>HRD Book नेपाली र english ,मा </div> */}
										</div>
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div >
			{/* <ContactUS/> */}
			{
				reload &&
				<div className="modal" style={{ padding: "50vw" }}>
					<div className="dot-pulse"></div>
				</div>
			}

		</div >
	)
}


const CaroselView = ({
	HeaderDesktopTabimagesPass,
	HeaderMobileimagesPass,
	transitionTime,
	interval,
	centerSlidePercentage
}) => {
	return (
		<section id="carosel-1">
			{/* <div className="Home-Carosel-Desktop-Style p-1">
				<Carousel
					autoPlay={true}
					swipeable={true}
					emulateTouch={true}
					infiniteLoop={true}
					dynamicHeight={true}
					showThumbs={false}
					transitionTime={transitionTime}
					interval={interval}
					centerMode={true}
					centerSlidePercentage={centerSlidePercentage}
				>
					{HeaderMobileimagesPass.map((item, index) => (
						<div key={index}
							className="Carosel-Image"
						>
							<img src={item}
								className="homeImage"
							/>
						</div>
					))}
				</Carousel>
			</div> */}
			<div className="Home-Carosel-Mobile-Style p-1">
				<Carousel
					dynamicHeight={false}
					showThumbs={false}
					infiniteLoop={true}
					autoPlay={true}
					transitionTime={transitionTime}
					interval={interval}
				>
					{HeaderMobileimagesPass.map((item, index) => (
						<div key={index}
							className="Carosel-Image"
						>
							<img src={item}
								className="homeImage"
							/>
						</div>
					))}
				</Carousel>
			</div>
		</section>

	)
}